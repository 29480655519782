import React, { forwardRef } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


import DashboardIcon from '../../assets/img/Dashboard.png'
import CallTrackingIcon from '../../assets/img/Call-Tracking.png'
import MisscalledIcon from '../../assets/img/Missed-Calls.png'
import RoiIcon from '../../assets/img/ROI-Channel.png'
import CallIcon from '../../assets/img/Calls.png'


const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));


const useStyles = makeStyles((theme) => ({
  img: {
    width: 25
  },
}));

const Pages = () => {
    const classes = useStyles();

    const routers = [
        // {
        //     icon: <img src={DashboardIcon} style={{ color: '#438afe' }} className={classes.img} />,
        //     name: 'Dashboard',
        //     href: '/dashboard',
        // },
        {
            icon: <img src={DashboardIcon} style={{ color: '#438afe' }} className={classes.img} />,
            name: 'Dashboard',
            href: '/dashboard',
        },
        {
            icon: <img src={CallIcon} style={{ color: '#438afe' }} className={classes.img} />,
            name: 'Calls',
            href: '/calls',
        },
        {
            icon: <img src={MisscalledIcon} style={{ color: '#438afe' }} className={classes.img} />,
            name: 'Missed Calls',
            href: '/missedcalls',
        },
        {
            icon: <img src={RoiIcon} style={{ color: '#438afe' }} className={classes.img} />,
            name: 'ROI Channel',
            href: '/roichannel',
        },
        {
            icon: <img src={CallTrackingIcon} style={{ color: '#438afe' }} className={classes.img} />,
            name: 'Call Tracking',
            href: '/calltracking',
        },
    ];

    return (
        <div>
            {routers.map((router, index) => {
                return (
                    <ListItem button component={CustomRouterLink} to={router.href} key={index}>
                        <ListItemIcon>{router.icon}</ListItemIcon>
                        <ListItemText primary={router.name} style={{ color: '#fff' }} />
                    </ListItem>
                );
            })}
        </div>
    );
};
export default Pages;
