import heydayImage from "../assets/img/logoWhite.png";
import maiaImage from "../assets/organization/maia.png";
import kaeImage from "../assets/organization/kae.png";
import xprintxImage from "../assets/organization/xprintx.png";
import suelaImage from "../assets/organization/suela.png";
import sdtphoneImage from "../assets/organization/sdt.png";
import sdkImage from "../assets/organization/sdkwhite.png";
import sdkDarkImage from "../assets/organization/sdk.png";
import westlandImage from "../assets/organization/westland.png";
import jmhondaImage from "../assets/organization/jmhonda.png";

export const organization = () => {
  const hostname = window.location.hostname.split(".");

  const theme = {
    primary: '#438afe',
    secondary: '#00affe'
  }

  const useAllOrganization = {
    heyday: { logo: heydayImage, theme },
    jmhonda: {
      logo: jmhondaImage, logoDark: jmhondaImage, theme: {
        primary: '#ee3e44',
        secondary: '#821e2b'
      }
    },
    maia: { logo: maiaImage, theme },
    kae: { logo: kaeImage, theme },
    xprintx: { 
      logo: xprintxImage,
      logoDark: xprintxImage,
      theme: {
        primary: '#2C2E83',
        secondary: '#2AA1D2'
      }
    },
    suela: { logo: suelaImage, theme },
    sdtphone: { logo: sdtphoneImage, theme },
    westland:{ logo: westlandImage, theme },
    sdkphone: { logo: sdkImage, logoDark: sdkDarkImage, theme: {
          primary: '#ce112f',
          secondary: '#252525'
        } 
      }
  };

  for (let organization in useAllOrganization) {
    if (hostname.includes(organization)) {
      return {
        name: organization,
        logo: heydayImage,
        orgLogo: useAllOrganization[organization].logo,
        logoDark: useAllOrganization[organization]?.logoDark,
        theme: useAllOrganization[organization].theme,
      };
    }
  }

    return {
        name: 'heyday',
        logo: heydayImage,
        orgLogo: heydayImage,
        theme
    }
};
